<template>
  <section v-if="ready" class="CarlineSelectorCmp">
    <div class="container">
      <div class="row">
        <div class="col">
          <heading-cmp class="pb-8 pb-md-10 pb-lg-11">
            <span
              v-if="$context.cmsEntry.show_homepage_title"
              class="customTitle"
              v-html="computedTitle"
            />
            <template #subtitle>
              <div v-html="$context.cmsEntry.homepage_subtitle" />
            </template>
          </heading-cmp>
        </div>
      </div>
      <accordion-cmp
        :items="[
          { title: $context.cmsEntry.filter_cta_label, slot: 'filters' },
        ]"
        class="pb-8 filters-accordion"
      >
        <template #header="{ item }">
          <div class="filters-header px-6 py-5">
            <div class="row align-items-center">
              <div class="col">
                <h5 class="filters-title">{{ item.title }}</h5>
              </div>
              <div class="col-auto">
                <svg class="filters-icon">
                  <use href="#icon-filters" />
                </svg>
                <span v-if="filtersCount" class="filters-count">
                  {{ filtersCount }}
                </span>
              </div>
            </div>
          </div>
        </template>
        <template #filters>
          <div class="filters-content p-6">
            <div class="row gy-5 gx-0">
              <div v-if="brandsList.length > 0 && !isBrand" class="col-12">
                <p>{{ $context.cmsEntry.filter_selected_brand }}</p>
                <filter-checkbox-group-cmp
                  v-model="brandsFilter"
                  class="pt-3"
                  name="brand"
                  :options="brandsList"
                  :disabled="loadingMore"
                />
              </div>
              <div v-if="enginesList.length > 0" class="col-12">
                <p>{{ $context.cmsEntry.filter_selected_engine }}</p>
                <filter-checkbox-group-cmp
                  v-model="enginesFilter"
                  class="pt-3"
                  name="engine"
                  :options="enginesList"
                  :disabled="loadingMore"
                />
              </div>
              <div v-if="feeRange" class="col-12">
                <p>
                  {{ $context.cmsEntry.filter_selected_range_fee }}
                  <strong v-if="feeFilterExists" class="fee-values">
                    {{ feeFilter[0] }} € - {{ feeFilter[1] }} €
                  </strong>
                </p>
                <filter-range-cmp
                  v-model="feeFilter"
                  class="pt-3"
                  :min="feeRange[0]"
                  :max="feeRange[1]"
                  :interval="feeRange[2]"
                  :min-range="feeRange[3]"
                  :disabled="loadingMore"
                  @drag-end="debouncedFilter"
                />
              </div>
              <div v-if="filtersCount" class="col-12">
                <div class="row gx-3">
                  <div class="col-auto">
                    <cta-button-cmp
                      class="psa-button rounded-border px-5 reset-cta"
                      @click="onReset"
                    >
                      {{ $context.cmsEntry.filter_cta_reset_label }}
                    </cta-button-cmp>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </accordion-cmp>
      <div v-if="carlineList.length > 0" class="row carline-list">
        <div
          v-for="(item, i) in carlineList"
          :key="`carline-card-${i}-${_uid}`"
          class="col-12 col-md-6 col-lg-4 col-xl-3"
        >
          <div
            :class="[
              'carline-card',
              {
                'is-loading':
                  i + 1 === carlineList.length &&
                  carlinePage.next &&
                  loadingMore,
              },
            ]"
          >
            <div
              v-if="item.ribbon_text"
              class="ribbon"
              :style="componentStyles"
            >
              <span>{{ item.ribbon_text }}</span>
            </div>
            <div
              v-if="i + 1 === carlineList.length && carlinePage.next"
              v-intersect.once="onIntersect"
              :class="['intersect', { 'is-loading': loadingMore }]"
            >
              <transition name="loading">
                <loader-cmp v-if="loadingMore" />
              </transition>
            </div>
            <div class="frame">
              <div class="model">
                <span class="brand">{{ item.brand }}</span>
                <span class="name">{{ item.car_name }}</span>
              </div>
              <figure class="thumbnail">
                <img
                  :src="item.preview_image"
                  :alt="`${item.brand} ${item.car_name}`"
                  width="280"
                  height="180"
                />
              </figure>

              <p class="price">
                A partire da
                <span>
                  {{ item.start_fee }}€
                  <sub>/ mese*</sub>
                </span>
                <span class="vat">IVA INCLUSA</span>
                <svg class="infoIcon" @click="toggleAntitrustModal(item.id)">
                  <use href="#icon-info" fill="currentColor" />
                </svg>
              </p>
              <p v-if="item.claim_text" class="claimText pb-2">
                {{ item.claim_text }}
              </p>
              <p v-if="item.claim_text_antitrust" class="claimText pb-2">
                {{ item.claim_text_antitrust }}
              </p>
              <cta-button-cmp
                class="psa-button"
                @click.native="
                  i + 1 === carlineList.length &&
                  carlinePage.next &&
                  loadingMore
                    ? null
                    : onCarlineChosen(item.detail_url)
                "
              >
                CONFIGURA
              </cta-button-cmp>
              <p v-if="item.hurry_up_text" class="hurryUpText mt-2">
                {{ item.hurry_up_text }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="no-results">
        <template v-if="filtersCount">
          <p class="pb-5">{{ $context.cmsEntry.filter_no_result_label }}</p>
          <cta-button-cmp
            class="psa-button rounded-border px-5 reset-cta"
            @click="onReset"
          >
            {{ $context.cmsEntry.filter_cta_reset_label }}
          </cta-button-cmp>
        </template>
        <p v-else>{{ $context.cmsEntry.no_entry_label }}</p>
      </div>
      <div class="row">
        <div class="col">
          <p class="post-scriptum">
            {{ $context.cmsEntry.homepage_footnote }}
          </p>
        </div>
      </div>
    </div>
    <bootstrap-modal-cmp-new
      :ref="`$bootstrap-antitrust-modal-${_uid}`"
      class="toast"
      :data-scroll-top="false"
      :data-style="{
        fontSize: '1rem',
      }"
    >
      <template #close>Chiudi</template>
      <template #content>
        <div v-html="carlineAntitrustText" />
      </template>
    </bootstrap-modal-cmp-new>
  </section>
</template>

<script>
import CtaButtonCmp from '@components/cta-button-cmp';
import HeadingCmp from '@components/heading-cmp';
import LoaderCmp from '@components/loader-cmp';
import FilterCheckboxGroupCmp from '@components/filter-checkbox-group-cmp';
import FilterRangeCmp from '@components/filter-range-cmp';
import Intersect from '@directives/intersect';
import axios from 'axios';
import imagesLoaded from 'imagesloaded';
import BootstrapModalCmpNew from '@components/bootstrap-modal-cmp-new';
import AccordionCmp from '@frankhoodbs/lib/src/components/accordion-cmp';
import _debounce from 'lodash.debounce';

const RANGE_MAX = 1000000000;
const RANGE_MIN = -1;

export default {
  name: 'CarlineSelectorCmp',
  components: {
    CtaButtonCmp,
    HeadingCmp,
    LoaderCmp,
    BootstrapModalCmpNew,
    FilterCheckboxGroupCmp,
    FilterRangeCmp,
    AccordionCmp,
  },
  directives: {
    Intersect,
  },
  beforeRouteUpdate(to, from, next) {
    if (!this.ready || this.loadingMore) {
      return false;
    } else {
      next();
    }
  },
  data() {
    return {
      ready: false,
      carlinePage: {},
      carlineList: [],
      loadingMore: false,
      currentCarlineId: null,
      brandsFilter: [],
      enginesFilter: [],
      feeRange: [RANGE_MIN, RANGE_MAX],
      feeFilter: [RANGE_MIN, RANGE_MAX],
      isBrand: false,
      debouncedFilter: _debounce(this.onFilter, 200),
    };
  },
  computed: {
    carlineAntitrustText() {
      return !this.currentCarlineId
        ? ''
        : this.carlineList.find((c) => c.id === this.currentCarlineId)
            .antitrust_text;
    },
    componentStyles() {
      return {
        'backgroundColor':
          this.currentBrandData?.ribbonBackgroundColor || '#00a3e0',
        'color': this.currentBrandData?.ribbonForegroundColor || '#fff',
        '--ribbon-bg-color':
          this.currentBrandData?.ribbonBackgroundColor || '#00a3e0',
      };
    },
    currentBrand() {
      return this.$context.currentBrand;
    },
    brands() {
      return this.$context.brands;
    },
    currentBrandData() {
      return this.brands.find((b) => b.id === this.currentBrand);
    },
    computedTitle() {
      //alternativeTitleColor
      // const styleOrClass = this.currentBrand
      //   ? `style="color:${
      //       this.currentBrandData?.alternativeTitleColor
      //         ? this.currentBrandData?.alternativeTitleColor
      //         : '#00a3e0;'
      //     }"`
      //   : `class="text-color-primary"`;

      return this.$context.cmsEntry.homepage_title
        ? this.$context.cmsEntry.homepage_title
            .replace('%<%', `<mark>`)
            .replace('%>%', '</mark>')
        : '';
    },
    brandsList() {
      if (this.carlinePage?.filters_configuration?.brands) {
        return this.carlinePage.filters_configuration.brands.map((item) => {
          const brand = this.$context.brands.find((b) => b.id === item.slug);
          return {
            ...item,
            logo: brand?.brandIconPngUrl,
            logoSelected: brand?.brandIconSelectedPngUrl,
          };
        });
      }
      return [];
    },
    enginesList() {
      if (this.carlinePage?.filters_configuration?.engines) {
        return this.carlinePage.filters_configuration.engines;
      }
      return [];
    },
    params() {
      const p = {};
      if (this.enginesFilter.length > 0) {
        p.engine = this.enginesFilter.join(',');
      }
      if (this.brandsFilter.length > 0 && !this.isBrand) {
        p.brand = this.brandsFilter.join(',');
      }
      if (
        this.feeFilter[0] > RANGE_MIN &&
        this.feeFilter[0] > this.feeRange[0]
      ) {
        p.min_fee = this.feeFilter[0];
      }
      if (
        this.feeFilter[1] < RANGE_MAX &&
        this.feeFilter[1] < this.feeRange[1]
      ) {
        p.max_fee = this.feeFilter[1];
      }
      return p;
    },
    filtersCount() {
      let count = 0;
      if (this.$route.query.brand) {
        count++;
      }
      if (this.$route.query.engine) {
        count++;
      }
      if (this.$route.query.min_fee || this.$route.query.max_fee) {
        count++;
      }
      return count;
    },
    feeFilterExists() {
      return this.feeFilter[0] > RANGE_MIN && this.feeFilter[1] < RANGE_MAX;
    },
  },
  watch: {
    '$route.query': {
      immediate: false,
      handler() {
        this.loadQuery();
        this.loadingMore = true;
        this.getCarline(this.$context.carlinesApi)
          .then((response) => {
            if (response.data) {
              setTimeout(() => {
                this.carlinePage = response.data;
                this.carlineList = response.data.results;
                this.$nextTick(() => {
                  this.updateFilters();
                });
              }, 800); // same speed of css animation + the delay below
            }
          })
          .finally(() => {
            setTimeout(() => (this.loadingMore = false), 300); // a bit of delay to force the loader display
          });
      },
    },
    'brandsFilter'() {
      this.debouncedFilter();
    },
    'enginesFilter'() {
      this.debouncedFilter();
    },
  },
  mounted() {
    // change background to body ONLY for this page
    document.body.classList.add('bg-off-white');

    const brand = document
      .getElementById('app-root')
      .getAttribute('data-current-brand');
    this.isBrand = !!brand;
    if (this.isBrand) {
      this.brandsFilter = [brand];
    }

    this.$store.dispatch('setLoading', true);
    this.loadQuery();
    this.getCarline(this.$context.carlinesApi)
      .then((response) => {
        if (response.data) {
          this.carlinePage = response.data;
          this.carlineList.push(...response.data.results);
          this.$nextTick(() => {
            this.updateFilters();
          });
        }
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
        this.$store.dispatch('setLoading', false);
      })
      .finally(() => {
        this.ready = true;
        this.$nextTick(() => {
          imagesLoaded(this.$el, { background: true }, () => {
            setTimeout(() => {
              this.$store.dispatch('setLoading', false);
            }, 300);
          });
        });
      });
  },
  beforeDestroy() {
    // remove temp class added in mounted hook
    document.body.classList.remove('bg-off-white');
  },
  methods: {
    toggleAntitrustModal(id) {
      this.currentCarlineId = id;
      this.$refs[`$bootstrap-antitrust-modal-${this._uid}`].toggleModal(true);
    },
    getCarline(api, withParams = true) {
      if (api === 'useFixture') {
        return import('@fixtures/carline-list-fixture');
      } else {
        return axios.get(api, {
          params: withParams ? this.params : undefined,
        });
      }
    },
    onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.loadingMore = true;
        this.getCarline(this.carlinePage.next, false)
          .then((response) => {
            if (response.data) {
              setTimeout(() => {
                this.carlinePage = response.data;
                this.carlineList.push(...response.data.results);
              }, 800); // same speed of css animation + the delay below
            }
          })
          .finally(() => {
            setTimeout(() => (this.loadingMore = false), 300); // a bit of delay to force the loader display
          });
      }
    },
    loadQuery() {
      const { brand, engine, min_fee, max_fee } = this.$route.query;
      if (brand) {
        this.brandsFilter = brand.split(',');
      } else if (!this.isBrand) {
        this.brandsFilter = [];
      }
      if (engine) {
        this.enginesFilter = engine.split(',');
      } else {
        this.enginesFilter = [];
      }
      let newMin = RANGE_MIN;
      if (typeof min_fee !== 'undefined') {
        newMin = parseInt(min_fee);
      }
      let newMax = RANGE_MAX;
      if (typeof max_fee !== 'undefined') {
        newMax = parseInt(max_fee);
      }
      this.feeFilter = [newMin, newMax];
    },
    updateFilters() {
      if (
        typeof this.carlinePage?.filters_configuration?.min_fee === 'number' &&
        typeof this.carlinePage?.filters_configuration?.max_fee === 'number'
      ) {
        this.feeRange = [
          this.carlinePage.filters_configuration.min_fee,
          this.carlinePage.filters_configuration.max_fee,
          1,
          20,
        ];
      } else {
        this.feeRange = [RANGE_MIN, RANGE_MAX, 1, 20];
      }
      const [min, max] = this.feeRange;
      let newMin = this.feeFilter[0];
      if (this.feeFilter[0] < 0 || min > this.feeFilter[0]) {
        newMin = min;
      }
      let newMax = this.feeFilter[1];
      if (this.feeFilter[1] >= RANGE_MAX || max < this.feeFilter[1]) {
        newMax = max;
      }
      this.feeFilter = [newMin, newMax];
    },
    onFilter() {
      if (!this.ready || this.loadingMore) {
        return false;
      }
      this.$router.push({ name: 'home', query: this.params });
    },
    onReset() {
      if (!this.ready || this.loadingMore) {
        return false;
      }
      this.brandsFilter = [];
      this.enginesFilter = [];
      this.feeRange = [RANGE_MIN, RANGE_MAX];
      this.feeFilter = [RANGE_MIN, RANGE_MAX];
    },
    onCarlineChosen(url) {
      window.location.href = url;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';

.CarlineSelectorCmp {
  .HeadingCmp /deep/ .customTitle mark {
    color: var(--brand-color);
  }
  .carline-list {
    position: relative;
    margin-top: -10px;
    margin-bottom: -10px;

    .carline-card {
      position: relative;
      margin: 10px 0;
      background: $color-white;
      border: 2px solid $color-grey;

      &.is-loading {
        .CtaButtonCmp {
          cursor: default;
        }
      }

      .intersect {
        position: absolute; // relative to carline-list
        bottom: -15px; // same of margin bottom of the card minus half of the loader height
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 0;

        .loading-enter-active,
        .loading-leave-active {
          transition: opacity 0.5s;
        }

        .loading-enter,
        .loading-leave-to {
          opacity: 0;
        }
      }

      .ribbon {
        position: absolute;
        top: 15%;
        right: -20px;
        max-width: 250px;
        padding: 5px 10px;
        margin: 10px 0;
        text-align: left;
        z-index: map_get($z, overlay);

        &:after {
          // ribbon fold
          content: '';
          position: absolute;
          bottom: -14px;
          right: 4px;
          width: 0;
          height: 0;
          border-left: 0 solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 18px solid var(--ribbon-bg-color); // usa variable to set color by styles
          z-index: map_get($z, under);
          transform: rotate(90deg);
          filter: brightness(0.5);
        }

        span {
          // ribbon text
          display: block;
          max-height: 150px; // in case of extremely long data entry
          font-size: 10px;
          overflow: hidden;
        }
      }

      .frame {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 25px 30px 20px;

        /**
         * fix for very small devices only
         */
        @media (max-width: 360px) {
          overflow: hidden;
        }

        .model {
          width: 100%;
          display: flex;
          flex-direction: column;
          font-weight: $fh-bold;

          .brand {
            font-size: 13px;
            color: $color-light-grey;
            text-transform: uppercase;
            padding: 0 0 6px;
          }

          .name {
            font-size: 21px;
          }
        }

        .thumbnail {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 164px;
          margin: 20px 0;
          position: relative;

          img {
            height: 100%;

            @include media-breakpoint-up(md) {
              height: 110%;
            }
          }
        }

        .price {
          font-size: 14px;
          font-weight: $fh-light;
          text-align: center;
          margin: 0 0 15px;
          position: relative;
          width: 100%;

          .infoIcon {
            cursor: pointer;
            width: 30px;
            height: 30px;
            position: absolute;
            top: 50%;
            left: 0;
            color: $color-secondary;
            transform: translateY(-50%);
          }

          span {
            display: block;
            font-size: 28px;
            font-weight: $fh-bold;
            margin-top: 4px;

            sub {
              bottom: 0;
              font-size: 13px;
              font-weight: $fh-light;
              color: $color-dark-grey;
            }
          }

          .vat {
            font-size: 13px;
            line-height: 1;
            font-weight: $fh-light;
            color: $color-dark-grey;
            margin: 6px 0 0;
          }
        }

        .claimText {
          font-size: 10px;
          font-weight: 300;
          color: $color-dark-grey;
          text-align: center;
        }
        .hurryUpText {
          font-size: 10px;
          font-weight: 700;
          color: $color-error;
          text-align: center;
        }
      }
    }
  }

  .post-scriptum {
    font-size: 12px;
    font-weight: $fh-light;
    color: $color-dark-grey;
    padding: 20px 0 0;

    @include media-breakpoint-up(md) {
      padding: 30px 0 0;
      font-size: 15px;
    }

    @include media-breakpoint-up(lg) {
      padding: 40px 0 0;
      font-size: 16px;
    }
  }
}

.fee-values {
  background: $color-almost-black;
  color: $color-white;
  display: inline-block;
  padding: 4px 8px;
  line-height: 1;
  font-size: 14px;
  border-radius: 8px;
  vertical-align: middle;
}

.filters-accordion {
  /deep/ {
    .item {
      border: 0;
    }

    .content,
    .header-link {
      padding: 0;
    }
  }
}

.filters-header {
  border: 1px solid $color-grey;
  background: $color-white;
  position: relative;
}

.filters-title {
  font-weight: $fh-bold;
  font-size: 18px;
  line-height: 1;
}

.filters-icon {
  width: 24px;
  height: 24px;
  display: block;
  color: $color-light-grey;
}

.filters-content {
  border: 1px solid $color-grey;
  border-top: 0;
  background: $color-white;
}

.filters-count {
  position: absolute;
  right: 16px;
  top: 16px;
  background: $color-error;
  color: $color-white;
  font-weight: $fh-bold;
  font-size: 12px;
  line-height: 1;
  padding: 4px;
  min-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.no-results {
  text-align: center;
  color: $color-dark-grey;

  .psa-button {
    display: inline-block;
    width: auto;
  }
}

.reset-cta {
  color: var(--brand-color) !important;
  background: transparent !important;
  border-width: 1px !important;
  border-style: solid !important;
}
</style>
